import React, { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Operation } from 'fast-json-patch';

import { isUserAssignedRole, useAccount } from '@hcs/auth';
import { Button } from '@hcs/design-system';
import { Checkbox } from '@hcs/design-system';
import { ActionButtons } from '@hcs/design-system';
import { Anchor } from '@hcs/design-system';
import { Ledger } from '@hcs/design-system';
import { TwoColumnTable } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { NullState } from '@hcs/design-system';
import { ExperienceFlag } from '@hcs/experience-flags';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { getPropertyStateFieldValue } from '@hcs/property-state';
import {
  MeaningfulEventTypes,
  PropertyStateArgsCore,
  PropertyStateEditCallbackArgs,
  PropertyStateFields,
  PropertyStateType,
  Roles,
} from '@hcs/types';
import { ReportFeatures, ReportId, ValuationMethod } from '@hcs/types';
import { formatMoney } from '@hcs/utils';
import { combineUseQueryResult } from '@hcs/utils';

import { UserPropertyAdjustedBadge } from '../../components-deprecated';
import { SELECTED_VALUE_PATH } from '../../constants/subjectDetails.constants';
import {
  useDocumentPatch,
  useReportPermissions,
  useSubjectDocument,
  useSubjectValueDocument,
} from '../../hooks';
import { usePreviewAdjustedAvm } from '../../hooks/usePreviewAdjustedAvm';
import { useReportConfig } from '../../hooks/useReportConfig';
import { useRevertSubjectPropertyAdjustments } from '../../hooks/useRevertSubjectPropertyAdjustments';
import { ReportFeaturesSupported } from '../ReportFeaturesSupported';

import styles from './SubjectDetailsTable.module.css';

export const DEFAULT_COLS: PropertyStateFields[][] = [
  [
    PropertyStateFields.livingArea,
    PropertyStateFields.lotSize,
    PropertyStateFields.bedrooms,
    PropertyStateFields.bathrooms,
    PropertyStateFields.stories,
    PropertyStateFields.propertyType,
    PropertyStateFields.ownerOccupied,
    PropertyStateFields.yearBuilt,
    PropertyStateFields.basementHas,
    PropertyStateFields.pool,
    PropertyStateFields.daysOnMarketCumulative,
    PropertyStateFields.apn,
    PropertyStateFields.concessions,
    PropertyStateFields.concessionsComments,
    PropertyStateFields.concessionSellerAmount,
    PropertyStateFields.concessionSellerDescription,
  ],
  [
    PropertyStateFields.garageType,
    PropertyStateFields.garageSpaces,
    PropertyStateFields.condition,
    PropertyStateFields.subdivisionName,
    PropertyStateFields.zoning,
    PropertyStateFields.associationName,
    PropertyStateFields.associationFee,
    PropertyStateFields.associationFeeFrequency,
    PropertyStateFields.associationFeeIncludes,
    PropertyStateFields.taxYear,
    PropertyStateFields.taxAmount,
    PropertyStateFields.county,
    PropertyStateFields.concessionsAmount,
    PropertyStateFields.concessionBuyerAmount,
    PropertyStateFields.concessionBuyerDescription,
  ],
];

export type SubjectDetailsTableTheme = {
  Table: string;
  Control: string;
  Row: string;
  ActionBar: string;
  TwoColumnTable: string;
  Value: string;
  RightColumn: string;
  Column: string;
};

interface Props {
  reportId: ReportId;
  className?: string;
  theme?: Partial<SubjectDetailsTableTheme>;
  propertyFieldsTables?: PropertyStateFields[][];
}
const dataHcName = 'subject-details-table';
export const dataHcEventSectionEditSubjectDetails =
  'Edit Subject Property Details';
export const SUBJECT_DETAILS_TABLE_FEATURES = [
  ReportFeatures.SubjectPropertyDetails,
  ReportFeatures.SubjectEditDetails,
  ReportFeatures.SubjectEditCondition,
];
export const SubjectDetailsTable = ({
  reportId,
  theme,
  propertyFieldsTables = DEFAULT_COLS,
  className,
}: Props) => {
  const { data: account } = useAccount();
  const isBrokerRole = isUserAssignedRole([Roles.Broker], account);
  const [isAdjusting, setIsAdjusting] = useState(false);
  const [adjustmentValuePreview, setAdjustmentValuePreview] = useState<
    number | null
  >(null);
  const [operations, setOperations] = useState<Operation[]>([]);
  const [valuationMethod, setValuationMethod] = useState<
    ValuationMethod | undefined
  >();
  const documentPatchMutation = useDocumentPatch(reportId);
  const { mutate: previewAdjustedAvmMutation } = usePreviewAdjustedAvm();
  const subjectDocumentQuery = useSubjectDocument(reportId);
  const reportConfigQuery = useReportConfig(reportId);
  const subjectValueDocumentQuery = useSubjectValueDocument(reportId);
  const reportPermissionsQuery = useReportPermissions(reportId);
  const combinedQuery = combineUseQueryResult([
    subjectDocumentQuery,
    reportConfigQuery,
    subjectValueDocumentQuery,
    reportPermissionsQuery,
  ]);
  const revertSubjectPropertyAdjustments =
    useRevertSubjectPropertyAdjustments(reportId);
  const { data: reportPermissions } = reportPermissionsQuery;
  const { data: subjectDocument } = subjectDocumentQuery;
  const { data: reportConfig } = reportConfigQuery;
  const { data: subjectValueDocument } = subjectValueDocumentQuery;
  const adjustments = subjectDocument?.userPropertyAdjustments?.adjustments;
  const containsSavedUserAdjustedValues =
    adjustments && Object.keys(adjustments).length > 0;

  useEffect(() => {
    if (operations.length) {
      previewAdjustedAvmMutation(
        { reportId, operations },
        {
          onSuccess: (data) => {
            setAdjustmentValuePreview(data.value.value || null);
          },
        }
      );
    }
  }, [operations, reportId, previewAdjustedAvmMutation]);

  if (combinedQuery.isInitialLoading) {
    return <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} />;
  }

  const subjectSchema = subjectDocument?.data;

  if (combinedQuery.isError || !subjectSchema) {
    return (
      <NullState
        dataHcName={`${dataHcName}-error`}
        title="Error Loading Subject"
      />
    );
  }

  const handleChange = (
    args: PropertyStateEditCallbackArgs<PropertyStateFields>
  ): void => {
    const operation: Operation = {
      path: `/data/propertyState${args.path}`,
      op: 'add',
      value: args.value,
    };

    const subjectDetailCurrentValue = getPropertyStateFieldValue(args.field, {
      propertyStateType: PropertyStateType.Core,
      propertyState: subjectSchema.propertyState,
    });

    const parsedSubjectDetailCurrentValue =
      // Handle original detail values that are floats
      typeof subjectDetailCurrentValue === 'number' &&
      args.field !== PropertyStateFields.bathrooms
        ? Math.round(subjectDetailCurrentValue || 0)
        : subjectDetailCurrentValue;

    if (parsedSubjectDetailCurrentValue === operation.value) {
      setOperations([...operations.filter((op) => op.path !== operation.path)]);
    } else if (parsedSubjectDetailCurrentValue !== operation.value) {
      setOperations([
        ...operations.filter((op) => op.path !== operation.path),
        operation,
      ]);
    }
  };

  const cancelAdjustments = () => {
    setOperations([]);
    setAdjustmentValuePreview(null);
    setIsAdjusting(false);
  };

  const patchAdjustments = () => {
    /**
     * If a user adjusts property details and selects to use their adjusted
     * value for the report, Report-API currently needs to process adjusted
     * operations first.
     *
     * This is because without first processing those adjusted operations,
     * our "adjusted value" will be null.
     *
     * Reference EXP-1840 for more details.
     */
    if (operations.length && valuationMethod && subjectValueDocument) {
      documentPatchMutation.mutate(
        {
          reportId,
          document: subjectDocument,
          operations: operations,
        },
        {
          onSettled: () => {
            documentPatchMutation.mutate({
              reportId,
              document: subjectValueDocument,
              operations: [
                {
                  op: 'replace',
                  path: SELECTED_VALUE_PATH,
                  value: valuationMethod,
                },
              ],
            });
          },
        }
      );
    } else {
      if (valuationMethod && subjectValueDocument) {
        documentPatchMutation.mutate({
          reportId,
          document: subjectValueDocument,
          operations: [
            {
              op: 'replace',
              path: SELECTED_VALUE_PATH,
              value: valuationMethod,
            },
          ],
        });
      }

      if (operations.length) {
        documentPatchMutation.mutate({
          reportId,
          document: subjectDocument,
          operations: operations,
        });
      }
    }

    setOperations([]);
    setAdjustmentValuePreview(null);
    setIsAdjusting(false);
    setValuationMethod(undefined);
  };

  const hcAvm = subjectValueDocument?.data[ValuationMethod.Avm]?.value.value;
  const adjustedAvm =
    isAdjusting && adjustmentValuePreview !== null
      ? adjustmentValuePreview
      : subjectValueDocument?.data[ValuationMethod.Adjusted]?.value.value;
  const adjustedValueDiff =
    adjustedAvm != null && hcAvm != null ? adjustedAvm - hcAvm : undefined;
  const propertyStateArgs: PropertyStateArgsCore = {
    propertyStateType: PropertyStateType.Core,
    propertyState: subjectDocument.data.propertyState,
  };
  const selectValueDisabled =
    !containsSavedUserAdjustedValues && operations.length === 0;
  const selectValueChecked =
    valuationMethod === undefined
      ? subjectValueDocumentQuery.data?.data.selectedValue ===
        ValuationMethod.Adjusted
      : valuationMethod === ValuationMethod.Adjusted;
  const saveAdjustmentsDisabled = operations.length === 0 && !valuationMethod;
  return (
    <div
      data-hc-name={`${dataHcName}-container`}
      data-hc-event-section={
        isAdjusting ? dataHcEventSectionEditSubjectDetails : undefined
      }
      className={styles.SubjectDetailsTableContainer}
    >
      <ReportFeaturesSupported
        reportId={reportId}
        reportFeatures={[
          ReportFeatures.SubjectEditDetails,
          ReportFeatures.SubjectEditCondition,
        ]}
      >
        {reportPermissions?.isEditable && (
          <span
            data-hc-event-section={dataHcEventSectionEditSubjectDetails}
            className={classNames(styles.ActionBar, theme?.ActionBar)}
          >
            {!isAdjusting ? (
              <Anchor
                dataHcName={`${dataHcName}-adjust`}
                className={classNames(styles.Control, theme?.Control)}
                onClick={() => setIsAdjusting(!isAdjusting)}
              >
                Adjust Fields
              </Anchor>
            ) : (
              <ExperienceFlag
                experienceFlagId="PEXP_REDESIGN"
                now={
                  <>
                    <Anchor
                      dataHcName={`${dataHcName}-cancel`}
                      className={classNames(styles.Control, theme?.Control)}
                      onClick={cancelAdjustments}
                    >
                      Cancel
                    </Anchor>
                    <Anchor
                      dataHcName={`${dataHcName}-save`}
                      className={classNames(styles.Control, theme?.Control)}
                      onClick={patchAdjustments}
                    >
                      Save
                    </Anchor>
                  </>
                }
              />
            )}
          </span>
        )}
      </ReportFeaturesSupported>
      <div
        className={classNames(styles.SubjectDetailsTable, className)}
        data-hc-name={dataHcName}
      >
        {propertyFieldsTables.map((propertyFieldsTable, i) => {
          return (
            <div
              key={`table-${i}`}
              className={classNames(styles.Column, theme?.Column)}
              style={{
                flex: `0 0 calc(${
                  (1 / propertyFieldsTables.length) * 100
                }% - 15px)`,
              }}
            >
              <TwoColumnTable
                badgesEnabled
                dataHcName={`${dataHcName}-${i}`}
                theme={{
                  Row: classNames(
                    styles.Row,
                    {
                      [styles.isNotAdjusting]: !isAdjusting,
                    },
                    theme?.Row
                  ),
                  Value: isAdjusting
                    ? classNames(styles.Value)
                    : classNames(styles.Value, theme?.Value),
                  TwoColumnTable: theme?.TwoColumnTable,
                }}
                rows={propertyFieldsTable.map((compField) => {
                  const { label, Display, Edit } =
                    PROPERTY_STATE_FIELD_CONFIGS[compField];

                  const compFieldUserAdjustment =
                    subjectDocument?.userPropertyAdjustments?.adjustments?.[
                      compField
                    ];

                  const Badge: ReactNode = compFieldUserAdjustment &&
                    compFieldUserAdjustment.currentValue !==
                      compFieldUserAdjustment.sourceValue && (
                      <UserPropertyAdjustedBadge
                        dataHcName={`${dataHcName}-comp-field-user-adjustment`}
                        label={label}
                        value={
                          <Display
                            key={`subject-col-${compField}`}
                            propertyStateArgs={{
                              propertyStateType: 'flat',
                              propertyState: {
                                [compField]:
                                  compFieldUserAdjustment.sourceValue,
                              },
                            }}
                          />
                        }
                      />
                    );

                  return {
                    badges: isAdjusting && Badge,
                    value:
                      isAdjusting &&
                      Edit &&
                      (compField === PropertyStateFields.condition
                        ? reportConfig?.reportFeaturesSupport[
                            ReportFeatures.SubjectEditCondition
                          ]
                        : reportConfig?.reportFeaturesSupport[
                            ReportFeatures.SubjectEditDetails
                          ]) ? (
                        <div className={styles.EditWrapper}>
                          <Edit
                            key={`edit-subject-col-${compField}`}
                            propertyStateArgs={propertyStateArgs}
                            onChange={handleChange}
                          />
                        </div>
                      ) : (
                        <div className={styles.DisplayWrapper}>
                          <Display
                            key={`subject-col-${compField}`}
                            propertyStateArgs={propertyStateArgs}
                          />
                          {Badge}
                        </div>
                      ),
                    label,
                  };
                })}
              />
            </div>
          );
        })}
      </div>
      <ExperienceFlag
        experienceFlagId="PEXP_REDESIGN"
        now={
          adjustedValueDiff ? (
            <div
              className={styles.AdjustedValueDiff}
              data-hc-name={`${dataHcName}-adjusted-difference`}
            >
              <div
                className={styles.AdjustedValueDiffLabel}
                data-hc-name={`${dataHcName}-adjusted-difference-label`}
              >
                Adjusted Value Difference
              </div>
              <div
                className={classNames(styles.AdjustedValueDiffValue, {
                  [styles.positive]: adjustedValueDiff > 0,
                  [styles.negative]: adjustedValueDiff < 0,
                })}
                data-hc-name={`${dataHcName}-adjusted-difference-value`}
              >
                {`${adjustedValueDiff > 0 ? '+' : ''}${formatMoney(
                  adjustedValueDiff
                )}`}
              </div>
            </div>
          ) : null
        }
        next={
          <ReportFeaturesSupported
            reportId={reportId}
            reportFeatures={[
              ReportFeatures.SubjectEditDetails,
              ReportFeatures.SubjectEditCondition,
            ]}
          >
            {isAdjusting && (
              <div
                data-hc-name={`${dataHcName}-adjusting-summary`}
                className={styles.AdjustingSummary}
              >
                <div className={styles.Column}>
                  <Ledger
                    dataHcName={`${dataHcName}-adjustment-breakdown`}
                    items={[
                      {
                        arrayKey: 'hc-estimated-value',
                        label: 'HC Estimated Value',
                        value:
                          subjectValueDocument?.data[ValuationMethod.Avm]?.value
                            .value,
                        className: styles.LedgerItem,
                      },
                      {
                        arrayKey: 'adjusted-value-difference',
                        label: 'Adjusted Value Difference',
                        value:
                          adjustedAvm && hcAvm ? adjustedAvm - hcAvm : null,
                        className: classNames(styles.LedgerItem, {
                          [styles.positive]:
                            adjustedAvm && hcAvm && adjustedAvm > hcAvm,
                          [styles.negative]:
                            adjustedAvm && hcAvm && adjustedAvm < hcAvm,
                        }),
                      },
                    ]}
                    total={{
                      label: 'Adjusted Value',
                      value: adjustedAvm || hcAvm,
                    }}
                  />
                </div>
                <div className={styles.Column}>
                  <ActionButtons
                    className={styles.ActionButtons}
                    dataHcName={`${dataHcName}-action-buttons`}
                    noStretch
                  >
                    <Button
                      label="Save Adjustments"
                      dataHcName={`${dataHcName}-save`}
                      onClick={patchAdjustments}
                      disabled={saveAdjustmentsDisabled}
                      dataHcEventName={
                        saveAdjustmentsDisabled
                          ? undefined
                          : 'Saved Adjustments'
                      }
                      dataHcEventType={
                        saveAdjustmentsDisabled
                          ? undefined
                          : MeaningfulEventTypes.Goal
                      }
                    />
                    <Anchor
                      dataHcName={`${dataHcName}-cancel`}
                      className={classNames(styles.Control, theme?.Control)}
                      onClick={cancelAdjustments}
                    >
                      Cancel
                    </Anchor>
                    <Anchor
                      disabled={!containsSavedUserAdjustedValues}
                      aria-disabled={!containsSavedUserAdjustedValues}
                      dataHcName={`${dataHcName}-reset`}
                      className={classNames(styles.Control, theme?.Control)}
                      onClick={() =>
                        revertSubjectPropertyAdjustments(() => {
                          setOperations([]);
                          setAdjustmentValuePreview(null);
                          setIsAdjusting(false);
                          setValuationMethod(undefined);
                        })
                      }
                    >
                      Reset
                    </Anchor>
                  </ActionButtons>
                  <Checkbox
                    disabled={selectValueDisabled}
                    checked={selectValueChecked}
                    className={styles.Checkbox}
                    dataHcName={`${dataHcName}-apply-to-report`}
                    dataHcEventName={
                      selectValueDisabled
                        ? undefined
                        : selectValueChecked
                        ? 'Deselected Adjusted Avm'
                        : 'Selected Adjusted Avm'
                    }
                    dataHcEventType={
                      selectValueDisabled
                        ? undefined
                        : MeaningfulEventTypes.Goal
                    }
                    label="Use your adjusted value for report?"
                    detail={
                      !isBrokerRole &&
                      "This value is based on adjustments that have been made to the property's core characteristics. It does not represent a broker price opinion (BPO) or a formal appraisal."
                    }
                    onChange={(v) => {
                      v
                        ? setValuationMethod(ValuationMethod.Adjusted)
                        : subjectValueDocumentQuery.data?.data.selectedValue ===
                          ValuationMethod.Adjusted
                        ? setValuationMethod(ValuationMethod.Avm)
                        : setValuationMethod(undefined);
                    }}
                  />
                </div>
              </div>
            )}
          </ReportFeaturesSupported>
        }
      />
    </div>
  );
};

import { HomeMagnifyingGlassIcon } from '@hcs/design-system';
import { AppConfig, AppSlugs, ReportConfigSlugs } from '@hcs/types';

import PropertyExplorerProductImage from '../assets/product-images/property-explorer-image.png';

export const APP_CONFIG_PEXP: AppConfig = {
  name: 'Property Explorer',
  appSlug: AppSlugs.PropertyExplorer,
  applicationKey: 'pexp',
  rootPath: `/pexp`,
  Icon: HomeMagnifyingGlassIcon,
  descriptionLong: `
        Access data and details around individual homes, including historic price
        growth and much more, helping you understand the context behind a home's
        value and the neighborhood and market surrounding the home.
      `,
  descriptionShort:
    'Interactive valuation tool to pinpoint true value of a home',
  marketingUrl: 'https://www.housecanary.com/products/explorers/',
  marketingImage: PropertyExplorerProductImage,
};

export enum VIEW_PATHS_PEXP {
  // Report
  REPORT = 'report/:reportId',
  REPORT_W_VAL_TYPE = 'report/:reportId/:valuationType',
  COMP_SELECTION = 'report/:reportId/:valuationType/comps',
  PDF = 'report/:reportId/pdf',
  COMP_SELECTION_DRAW = 'report/:reportId/:valuationType/comps/draw',
  // Public Report
  PUBLIC_REPORT = 'shared/:reportId',
  PUBLIC_REPORT_W_VAL_TYPE = 'shared/:reportId/:valuationType',
  PUBLIC_COMP_SELECTION = 'shared/:reportId/:valuationType/comps',
  // App
  SEARCH = 'search',
  HUELL_FE_REDIRECT = 'create/:slug',
  REPORT_HISTORY = 'report-history',
  OFFER = 'offer',
  SELF_SERVICE_SETUP = 'setup',
  BIAS = 'bias',
}

export const PEXP_UI_REPORT_CONFIG_SLUGS = [
  ReportConfigSlugs.PexpDynamic,
  ReportConfigSlugs.PexpEffectiveDateDynamic,
];

import React, { ReactNode } from 'react';

import { Button } from '@hcs/design-system';
import { ThumbnailImage } from '@hcs/design-system';
import { ImageSection } from '@hcs/design-system';
import { StreetViewIcon } from '@hcs/design-system';
import { StreetViewLauncher } from '@hcs/street-view';
import { ReportFeatures, ReportId } from '@hcs/types';
import { formatNumber } from '@hcs/utils';
import { combineUseQueryResult } from '@hcs/utils';

import { usePhotosPage, useSubjectDocument } from '../../hooks';
import { useReportConfig } from '../../hooks/useReportConfig';
import { useSubjectPhotos } from '../../hooks/useSubjectPhotos';
import { reportFeaturesSupportedAny } from '../../utils/reportConfig.utils';
import {
  dataHcEventSectionSubjectPhotos,
  dataHcEventSectionSubjectStreetView,
} from '../ReportPhotosOverlayPage';

import styles from './SubjectImageSection.module.css';

interface Props {
  reportId: ReportId;
  className?: string;
}
const dataHcName = 'subject-image-section';
export const SUBJECT_IMAGE_SECTION_FEATURES = [
  ReportFeatures.SubjectPhotosCurrent,
  ReportFeatures.SubjectPhotosAll,
  ReportFeatures.SubjectStreetview,
];
export const SubjectImageSection = ({ reportId, className }: Props) => {
  const { photosPageOpen } = usePhotosPage();
  const { data: reportConfig } = useReportConfig(reportId);
  const subjectPhotosQuery = useSubjectPhotos(reportId);
  const subjectQuery = useSubjectDocument(reportId);
  const combinedQuery = combineUseQueryResult([
    subjectPhotosQuery,
    subjectQuery,
  ]);
  const { data: subjectPhotos } = subjectPhotosQuery;
  const { data: subjectDoc } = subjectQuery;
  const children: ReactNode[] = [];
  if (
    reportFeaturesSupportedAny(reportConfig, [
      ReportFeatures.SubjectPhotosCurrent,
      ReportFeatures.SubjectPhotosAll,
    ]) &&
    subjectPhotos?.previewPhoto &&
    (subjectPhotos.totalPhotos > 1 || !subjectPhotos.streetView?.url)
  ) {
    children.push(
      <ThumbnailImage
        onClick={() => photosPageOpen({ type: 'subject' })}
        key="front-photo"
        url={subjectPhotos.previewPhoto.url}
      />
    );
  }
  if (
    reportFeaturesSupportedAny(reportConfig, [
      ReportFeatures.SubjectStreetview,
    ]) &&
    subjectPhotos?.streetView &&
    subjectDoc?.data.propertyState
  ) {
    children.push(
      <StreetViewLauncher
        key="street-view-img"
        trigger={
          <ThumbnailImage key="streetview" url={subjectPhotos.streetView.url}>
            <Button
              className={styles.OverlayButton}
              dataHcName={`${dataHcName}-street-view-launcher-button`}
              icon={
                <StreetViewIcon
                  className={styles.StreetViewIcon}
                  color="neutral-light-10"
                />
              }
              overlay
            >
              <span className={styles.StreetViewText}>Street View</span>
            </Button>
          </ThumbnailImage>
        }
        location={subjectDoc.data.propertyState.location}
        dataHcName={`${dataHcName}-street-view-launcher`}
        dataHcEventSection={dataHcEventSectionSubjectStreetView}
        theme={{
          StreetViewLauncherButton: styles.StreetViewLauncherButton,
        }}
        options={{
          publicLinkUid: typeof reportId === 'string' ? reportId : undefined,
        }}
      />
    );
  }
  if (
    reportFeaturesSupportedAny(reportConfig, [
      ReportFeatures.SubjectPhotosCurrent,
      ReportFeatures.SubjectPhotosAll,
    ]) &&
    subjectPhotos?.listingPreviewPhoto
  ) {
    children.push(
      <ThumbnailImage
        key="otherImages"
        onClick={() => photosPageOpen({ type: 'subject' })}
        url={subjectPhotos.listingPreviewPhoto.url}
      >
        <Button
          className={styles.PhotoPageButton}
          dataHcName={`${dataHcName}-photo-page-button`}
          label={`See all ${formatNumber(subjectPhotos.totalPhotos)} photos`}
          overlay
        />
      </ThumbnailImage>
    );
  }
  return (
    <ImageSection
      isLoading={combinedQuery.isInitialLoading}
      dataHcName={dataHcName}
      dataHcEventSection={dataHcEventSectionSubjectPhotos}
      className={className}
      nullState={{
        isNull: !combinedQuery.isInitialLoading && !children.length,
        content: 'No Property Photos or Street View Are Available',
        className: styles.NoPhotosNullState,
      }}
    >
      {children}
    </ImageSection>
  );
};

import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ACCOUNT_FIELD_CONFIGS, AccountFieldInput } from '@hcs/auth';
import {
  Anchor,
  Button,
  Card,
  FormError,
  LoadingSpinner,
} from '@hcs/design-system';
import {
  AccountFields,
  CheckInvitationCodeResponse,
  SignUpFormFields,
  SignUpPayload,
} from '@hcs/types';

import { useCheckInvitationCode } from '../../hooks';
import { useSignUp, UseSignUpOptions } from '../../hooks/useSignUp';

import styles from './SignUp.module.css';

const yupSchema = yup.object().shape({
  [AccountFields.FirstName]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.FirstName].validator,
  [AccountFields.LastName]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.LastName].validator,
  [AccountFields.Email]: ACCOUNT_FIELD_CONFIGS[AccountFields.Email].validator,
  [AccountFields.Password]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.Password].validator,
});

const dataHcName = 'sign-up';
export interface SignUpProps {
  invitationCode?: string | null;
  navigateToLogin: VoidFunction;
  className?: string;
  title?: string;
  onSuccess?: UseSignUpOptions['onSuccess'];
}

const formValuesFromInvitationQuery = (
  data?: CheckInvitationCodeResponse
): SignUpFormFields | undefined => {
  if (data) {
    return {
      [AccountFields.FirstName]: data.firstName,
      [AccountFields.LastName]: data.lastName,
      [AccountFields.Email]: data.email,
      [AccountFields.Password]: '',
    };
  } else {
    return undefined;
  }
};
export const SignUp = ({
  className,
  invitationCode,
  onSuccess,
  navigateToLogin,
}: SignUpProps) => {
  const checkInvitationCodeQuery = useCheckInvitationCode(invitationCode);
  const form = useForm<SignUpFormFields>({
    resolver: yupResolver(yupSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { reset: formReset } = form;

  useEffect(() => {
    if (checkInvitationCodeQuery.data) {
      formReset({
        ...formValuesFromInvitationQuery(checkInvitationCodeQuery.data),
      });
    }
  }, [checkInvitationCodeQuery.data, formReset]);

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

  const signUpMutation = useSignUp({
    onError(error) {
      const errorMessage = error.response?.data.message || 'Sign Up failed';
      setErrorMessage(errorMessage);
    },
    onSuccess(response, payload) {
      form.reset();
      onSuccess?.(response, payload);
    },
  });

  const handleSubmit = (data: SignUpFormFields) => {
    const signUpPayload: SignUpPayload = {
      ...data,
    };
    if (invitationCode && checkInvitationCodeQuery.data) {
      signUpPayload.code = invitationCode;
      signUpPayload.companyName = checkInvitationCodeQuery.data.companyName;
    }
    signUpMutation.mutate(signUpPayload);
  };

  if (invitationCode && checkInvitationCodeQuery.isInitialLoading) {
    return (
      <LoadingSpinner dataHcName={`${dataHcName}-skeleton`} absoluteCenter />
    );
  }
  return (
    <Card dataHcName={dataHcName} className={className}>
      {errorMessage && (
        <FormError
          // Hardcoded to prevent current automation from breaking
          dataHcName={`${dataHcName}-errors`}
          className={styles.Error}
          value={<div>{errorMessage}</div>}
        />
      )}
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Controller
            name={AccountFields.FirstName}
            control={form.control}
            render={({ field }) => (
              <AccountFieldInput
                {...field}
                error={form.formState.errors[field.name]?.message}
              />
            )}
          />
          <Controller
            name={AccountFields.LastName}
            control={form.control}
            render={({ field }) => (
              <AccountFieldInput
                {...field}
                error={form.formState.errors[field.name]?.message}
              />
            )}
          />
          <Controller
            name={AccountFields.Email}
            control={form.control}
            render={({ field }) => (
              <AccountFieldInput
                {...field}
                disabled={!!invitationCode}
                error={form.formState.errors[field.name]?.message}
              />
            )}
          />
          <Controller
            name={AccountFields.Password}
            control={form.control}
            render={({ field }) => (
              <AccountFieldInput
                {...field}
                error={form.formState.errors[field.name]?.message}
              />
            )}
          />
          <div className={styles.ForgotPassword}>
            Already Have an Account?{' '}
            <Anchor
              dataHcName={`${dataHcName}-login`}
              onClick={navigateToLogin}
            >
              Login
            </Anchor>
          </div>
          <Button
            dataHcName={`${dataHcName}-submit-button`}
            label="Sign Up"
            disabled={!form.formState.isValid || signUpMutation.isLoading}
          />
        </form>
      </FormProvider>
    </Card>
  );
};

import { HcCompsIcon } from '@hcs/design-system';
import { AppSlugs, Roles } from '@hcs/types';
import { AppConfig } from '@hcs/types';

export const APP_CONFIG_CANARY_ADMIN: AppConfig = {
  name: 'Canary Admin',
  appSlug: AppSlugs.CanaryAdmin,
  applicationKey: 'internal-client-admin',
  rootPath: '/canary-admin',
  Icon: HcCompsIcon,
  descriptionLong: 'HouseCanary Staff User and Platform Permissions Management',
  descriptionShort: 'HouseCanary Staff Management',
  feOnlyApp: true,
  requiredRole: [Roles.CanaryAdmin],
};

export enum VIEW_PATHS_CANARY_ADMIN {
  FEATURE_FLAGS = 'feature-flags',
  USERS = 'users',
  USER = ':userId',
  USER_INTERNAL_PERMISSIONS = 'internal-permissions',
  USER_LOGIN_AS = 'login-as',
}

import { MarketPulseIcon } from '@hcs/design-system';
import { AppConfig, AppSlugs } from '@hcs/types';

export const APP_CONFIG_MARKET_PULSE: AppConfig = {
  name: 'Market Pulse',
  appSlug: AppSlugs.Platform,
  rootPath: `/`,
  Icon: MarketPulseIcon,
  applicationKey: 'market-pulse',
  descriptionLong:
    'Access to Market Pulse endpoints at the zipcode, MSA, or state level',
  descriptionShort:
    'Access to Market Pulse endpoints at the zipcode, MSA, or state level',
};

import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  useRedirectToSsoProvider,
  useSendSsoLoginLinkEmail,
} from '@hcs/account-api';
import {
  ACCOUNT_FIELD_CONFIGS,
  AccountFieldInput,
  useLogin,
  UseLoginOptions,
} from '@hcs/auth';
import { Anchor, Button, Card, FormError, Input } from '@hcs/design-system';
import { ExperienceFlag } from '@hcs/experience-flags';
import { useUrlState } from '@hcs/hooks';
import { AccountFields, AuthapiLoginRequestExt } from '@hcs/types';
import { URL_PARAM_REDIRECT } from '@hcs/webapps';

import styles from './Login.module.css';

const yupSchema = yup.object().shape({
  [AccountFields.Email]: ACCOUNT_FIELD_CONFIGS[AccountFields.Email].validator,
  [AccountFields.Password]:
    ACCOUNT_FIELD_CONFIGS[AccountFields.Password].validator,
});

const yupSchemaSsoLoginLink = yup.object().shape({
  [AccountFields.Email]: ACCOUNT_FIELD_CONFIGS[AccountFields.Email].validator,
});

const dataHcName = 'login-page-login';

type UrlState = 'login' | 'orgSlug' | 'loginLink';

export interface LoginProps {
  // Callback when clicking the Forgot Password link
  navigateToForgotPassword?: VoidFunction;
  className?: string;
  loginLinkUrl?: string;
  destinationUrl?: string;
  preventRedirect?: boolean;
  onLoginSuccess?: UseLoginOptions['onSuccess'];
}

export const Login = ({
  className,
  preventRedirect,
  onLoginSuccess,
  navigateToForgotPassword,
  loginLinkUrl,
  destinationUrl,
}: LoginProps) => {
  const {
    state: step,
    actions: { setUrlState },
  } = useUrlState<UrlState>('login', 'login');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [orgSlug, setOrgSlug] = useState('');
  const { mutate: redirectToSsoProvider } =
    useRedirectToSsoProvider(destinationUrl);
  const redirectParam = searchParams.get(URL_PARAM_REDIRECT);
  const form = useForm<AuthapiLoginRequestExt>({
    resolver: yupResolver(yupSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const ssoLoginLinkForm = useForm<{ email: string }>({
    resolver: yupResolver(yupSchemaSsoLoginLink),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { mutate: sendSsoLoginLinkEmail } = useSendSsoLoginLinkEmail();

  const [loginFailedMessage, setLoginFailedMessage] = useState<
    string | undefined
  >();

  const { mutate: login, isLoading } = useLogin({
    onError(error) {
      let errorMessage = 'Login failed';
      if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }

      setLoginFailedMessage(errorMessage);
    },
    onSuccess(response, loginPayload) {
      form.clearErrors();
      form.reset();
      onLoginSuccess?.(response, loginPayload);
      if (redirectParam && !preventRedirect) {
        navigate(redirectParam, { replace: true });
      }
    },
  });
  const { formState } = form;

  const handleLoginSubmit = (data: AuthapiLoginRequestExt) => {
    login(data);
  };

  const handleFieldChange = (
    value: string,
    onChange: (v: string) => void
  ): void => {
    onChange(value);
    if (loginFailedMessage) {
      setLoginFailedMessage(undefined);
    }
  };

  const handleChangeStep = (step: UrlState) => {
    setOrgSlug('');
    setUrlState(step, { replace: false });
  };

  const handleSsoLoginLinkSubmit = () => {
    sendSsoLoginLinkEmail({
      ...ssoLoginLinkForm.getValues(),
      url: loginLinkUrl,
    });
  };
  return (
    <Card dataHcName={dataHcName} className={className}>
      {step === 'login' && (
        <>
          {loginFailedMessage && (
            <FormError
              // Hardcoded to prevent current automation from breaking
              dataHcName={'login-errors'}
              className={styles.Error}
              value={<div>{loginFailedMessage}</div>}
            />
          )}
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleLoginSubmit)}>
              <Controller
                name={AccountFields.Email}
                control={form.control}
                render={({ field }) => (
                  <AccountFieldInput
                    {...field}
                    autoComplete="on"
                    // Hardcoded to prevent automation from breaking
                    dataHcName="login-page-login"
                    onChange={(value: string) =>
                      handleFieldChange(value, field.onChange)
                    }
                    error={form.formState.errors[field.name]?.message}
                  />
                )}
              />
              <Controller
                name={AccountFields.Password}
                control={form.control}
                render={({ field }) => (
                  <AccountFieldInput
                    {...field}
                    autoComplete="on"
                    onChange={(value: string) => {
                      handleFieldChange(value, field.onChange);
                    }}
                    // Hardcoded to prevent automation from breaking
                    dataHcName="login-page-login"
                    error={form.formState.errors[field.name]?.message}
                  />
                )}
              />
              {navigateToForgotPassword && (
                <div className={styles.ForgotPassword}>
                  <Anchor
                    dataHcName={`${dataHcName}-forgot-password`}
                    onClick={navigateToForgotPassword}
                  >
                    Forgot your password?
                  </Anchor>
                </div>
              )}
              <Button
                className={styles.LoginButton}
                dataHcName={`${dataHcName}-submit-button`}
                label="Login"
                disabled={!formState.isValid || isLoading}
              />
            </form>
          </FormProvider>
          <ExperienceFlag
            experienceFlagId="CLIENT_SSO_RELEASE"
            next={
              <>
                <div className={styles.Divider}>or</div>
                <Button
                  secondary
                  dataHcName={`${dataHcName}-sso`}
                  onClick={() => handleChangeStep('orgSlug')}
                >
                  Sign in with SSO
                </Button>
              </>
            }
          />
        </>
      )}
      {step === 'orgSlug' && (
        <>
          <label className={styles.Label}>Enter your Organization Slug:</label>
          <Input
            dataHcName={`${dataHcName}-org-slug`}
            value={orgSlug}
            onChange={setOrgSlug}
            placeholder="Organization Slug"
            onKeyUp={(e) => {
              if (e.key === 'Enter' && orgSlug) {
                redirectToSsoProvider({ orgSlug });
              }
            }}
          />
          <Button
            dataHcName={`${dataHcName}-sso-submit`}
            label="Continue"
            onClick={() => {
              redirectToSsoProvider({ orgSlug });
            }}
            disabled={!orgSlug}
          />
          <div className={styles.Divider}>or</div>
          <Button
            dataHcName={`${dataHcName}-sso-login-link`}
            onClick={() => handleChangeStep('loginLink')}
            secondary
          >
            Email a Login Link
          </Button>
          <Anchor
            dataHcName={`${dataHcName}-org-slug`}
            onClick={() => handleChangeStep('login')}
          >
            Sign in with email and password
          </Anchor>
        </>
      )}
      {step === 'loginLink' && (
        <FormProvider {...ssoLoginLinkForm}>
          <form
            onSubmit={ssoLoginLinkForm.handleSubmit(handleSsoLoginLinkSubmit)}
          >
            <Controller
              name={AccountFields.Email}
              control={ssoLoginLinkForm.control}
              render={({ field }) => (
                <AccountFieldInput
                  {...field}
                  autoComplete="on"
                  onChange={(value: string) =>
                    handleFieldChange(value, field.onChange)
                  }
                  error={ssoLoginLinkForm.formState.errors[field.name]?.message}
                />
              )}
            />
            <Button
              className={styles.LoginButton}
              dataHcName={`${dataHcName}-link-submit-button`}
              label="Send Login Link"
              disabled={!ssoLoginLinkForm.formState.isValid}
            />
          </form>
          <Anchor
            dataHcName={`${dataHcName}-org-slug`}
            onClick={() => handleChangeStep('login')}
          >
            Sign in with email and password
          </Anchor>
        </FormProvider>
      )}
    </Card>
  );
};

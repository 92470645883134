import { UserProfileIcon } from '@hcs/design-system';
import { AppConfig, AppSlugs } from '@hcs/types';

export const APP_CONFIG_USER_ADMIN: AppConfig = {
  name: 'User Settings',
  appSlug: AppSlugs.UserAdmin,
  Icon: UserProfileIcon,
  applicationKey: 'user-settings',
  rootPath: `/my-user`,
  descriptionLong: 'Control your user settings and preferences.',
  descriptionShort: 'Control your user settings and preferences.',
  feOnlyApp: true,
};

export enum VIEW_PATHS_USER_ADMIN {
  API_KEYS = 'api-keys',
  LEGACY_API_SETTINGS = '/api-settings',
  API_SUBSCRIPTIONS = 'api-subscriptions',
  USER_CONTACT_INFO = 'contact-info',
  LEGACY_PROFILE = '/profile',
  USER_CHANGE_PASSWORD = 'change-password',
  USER_USAGE = 'usage',
  LEGACY_USAGE = '/usage',
  REPORT_PREFERENCES = 'report-preferences',
  USER_EXPERIENCE_PREFERENCES = 'user-experience',
}

import { PayloadAction } from '@reduxjs/toolkit';

import {
  GlobalEngagementEventFields,
  GlobalEngagementEventSliceState,
} from '@hcs/types';
import { createHcReduxSlice } from '@hcs/utils';

type GlobalEventDataPayload = Partial<GlobalEngagementEventFields>;

const initialState: GlobalEngagementEventSliceState = {
  globalEngagementEventFields: {},
};
export const engagementTrackingGlobalDataSlice = createHcReduxSlice({
  name: 'globalData',
  initialState,
  reducers: {
    registerGlobalEventData(
      state,
      { payload: globalFields }: PayloadAction<GlobalEventDataPayload>
    ) {
      return {
        globalEngagementEventFields: {
          ...state.globalEngagementEventFields,
          ...globalFields,
        },
      };
    },
  },
});

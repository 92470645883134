import { PayloadAction } from '@reduxjs/toolkit';
import isEmpty from 'lodash.isempty';

import {
  EngagementEventData,
  EngagementTrackingEventDataSliceState,
} from '@hcs/types';
import { createHcReduxSlice } from '@hcs/utils';

interface RegisterEventDataPayload {
  /** Unique Id for component instance */
  componentId: string;
  /** Unique Id for the event data - Multiple components can register the same data */
  eventDataId: string;
  /** Event data to be sent with every tracking event */
  eventData: EngagementEventData;
}

type DeregisterEventDataPayload = Pick<
  RegisterEventDataPayload,
  'componentId' | 'eventDataId'
>;

const initialState: EngagementTrackingEventDataSliceState = {
  active: undefined,
};
export const engagementTrackingEventDataSlice = createHcReduxSlice({
  name: 'eventData',
  initialState,
  reducers: {
    registerEventData(
      state,
      {
        payload: { componentId, eventData, eventDataId },
      }: PayloadAction<RegisterEventDataPayload>
    ) {
      const eventDataState = state[eventDataId];
      if (!eventDataState) {
        state[eventDataId] = {
          components: [componentId],
          eventDataId,
          eventData,
        };
      } else {
        eventDataState.components = [
          ...eventDataState.components.filter((c) => c !== componentId),
          componentId,
        ];
        if (!isEmpty(eventData)) {
          eventDataState.eventData = eventData;
        }
      }
    },
    deregisterEventData(
      state,
      {
        payload: { eventDataId, componentId },
      }: PayloadAction<DeregisterEventDataPayload>
    ) {
      const eventDataState = state[eventDataId];
      if (eventDataState) {
        // Remove componentId from the list of listening components
        eventDataState.components = eventDataState.components.filter(
          (c) => c !== componentId
        );
      }
      // Delete registered event data if no components are listening
      if (!eventDataState?.components.length) {
        delete state[eventDataId];
      }
    },
  },
});

import { PayloadAction } from '@reduxjs/toolkit';

import { KeywordSearchInputs, KeywordSearchSliceState } from '@hcs/types';
import { createHcReduxSlice } from '@hcs/utils';
import { resetOnLogoutAction } from '@hcs/utils';

const initialState: KeywordSearchSliceState = {};
export const keywordSearchSlice = createHcReduxSlice({
  name: 'keywordSearch',
  initialState,
  extraReducers: (builder) =>
    builder.addCase(resetOnLogoutAction, () => initialState),
  reducers: {
    keywordSearchSubmit(
      state,
      action: PayloadAction<{
        searchKey: string;
        keywordSearchInputs: KeywordSearchInputs;
      }>
    ) {
      const { searchKey, keywordSearchInputs } = action.payload;
      state[searchKey] = keywordSearchInputs;
      return state;
    },
    keywordSearchClear(
      state,
      action: PayloadAction<{
        searchKey: string;
      }>
    ) {
      delete state[action.payload.searchKey];
      return state;
    },
  },
});

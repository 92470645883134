import { BulkPropertyDataIcon } from '@hcs/design-system';
import { AppConfig, AppSlugs } from '@hcs/types';

export const APP_CONFIG_BULK_PROPERTY_DATA: AppConfig = {
  name: 'Bulk Property Data',
  appSlug: AppSlugs.Platform,
  rootPath: `/`,
  Icon: BulkPropertyDataIcon,
  applicationKey: 'bulk-data',
  descriptionLong:
    'Bulk property data by state or national, delivered monthly.',
  descriptionShort: 'Bulk property data',
};

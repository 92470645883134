import { PayloadAction } from '@reduxjs/toolkit';

import { EngagementTrackingMeaningfulEventsSliceState } from '@hcs/types';
import { createHcReduxSlice } from '@hcs/utils';

const initialState: EngagementTrackingMeaningfulEventsSliceState = {
  eventSection: null,
};
export const engagementTrackingMeaningfulEventsSlice = createHcReduxSlice({
  name: 'meaningfulEvents',
  initialState,
  reducers: {
    setEventSection(
      state,
      {
        payload: { eventSection },
      }: PayloadAction<{ eventSection: string | null }>
    ) {
      state.eventSection = eventSection;
    },
  },
});

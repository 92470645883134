import {
  CaseReducerActions,
  configureStore,
  createSlice,
  MiddlewareArray,
  Slice,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import {
  combineReducers,
  Dispatch,
  Middleware,
  ReducersMapObject,
} from 'redux';

export const RESET_ON_LOGOUT_ACTION_TYPE = 'RESET_ON_LOGOUT';
export const resetOnLogoutAction = createAction(RESET_ON_LOGOUT_ACTION_TYPE);

type DispatchWrap = (...args: unknown[]) => void;
export const mapDispatchToSliceActions = <
  S,
  A extends SliceCaseReducers<S>,
  N extends string
>(
  dispatch: Dispatch,
  slice: Slice<S, A>
) => {
  const results: { [key: string]: DispatchWrap } = {};
  for (const name in slice.actions) {
    const fn = slice.actions[name];
    results[name] = (payload: unknown) => {
      const action = fn?.(payload);
      if (action) {
        dispatch(action);
      }
      return action;
    };
  }
  return results as CaseReducerActions<A, N>;
};

export const createHcReduxSlice: typeof createSlice = (createSliceOptions) => {
  return createSlice({
    ...createSliceOptions,
    reducers: {
      ...createSliceOptions.reducers,
      [RESET_ON_LOGOUT_ACTION_TYPE]: () => {
        return createSliceOptions.initialState;
      },
    },
  });
};

export const createHcReduxStore = (
  slices: Slice[],
  additionalReducers: ReducersMapObject = {}
) => {
  const sliceMapping: ReducersMapObject = { ...additionalReducers };
  slices.forEach((slice) => {
    sliceMapping[slice.name] = slice.reducer;
  });
  const reducers = combineReducers(sliceMapping);
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Options: https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
    // actionTransformer: catch actions to prevent logging or removing sensitive info
    // stateTransformer: transform state to remove sending sensitive info before logging
    // configureScopeWithState: configure Sentry scope with Redux state
  });
  return configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware() as unknown as MiddlewareArray<Middleware[]>,
    enhancers: [sentryReduxEnhancer],
  });
};
